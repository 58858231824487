import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getConfigLabels } from "../../utitlies/HelpersFunction";
import { internalFixedAuthRoutes } from "../../routes";
import { useIntl } from 'react-intl';

const internalFixedAuthRoutesUrls = internalFixedAuthRoutes.map((el) =>
  String(el.path).trim()
);

const defaultConfigLabelMenuSlugs = {
  ORG_KRAS: "",
  ORG_KPIS: "",
  ORG_COMPETENCIES: "",
  ORG_POTENTIAL: "",
  ORG_KEY_RESULT: ""
};

const Header = () => {
  const history = useNavigate();
  const location = useLocation();
  const intl = useIntl();
  const { user = {}, menu_access = [] } = useSelector(
    (state) => state.adminUserReducer,
    isEqual
  );
  const { childrenTabsData = [] } = useSelector(
    (state) => state.adminLocalStateReducer,
    isEqual
  );
  let { PRODUCT_THEME_COLOR: ptc } = user;
  const dispatch = useDispatch();

  const [updateName, setUpdateStateName] = useState(0);
  const { orgMenusAccess } = useSelector(
    (state) => state.organisationUnitsReducer,
    isEqual
  );

  const onTabChange = () => {
    if (!isEmpty(childrenTabsData)) {
      const val = new URL(window.location.href);
      let check = val.pathname?.indexOf("org-structure") > -1;
      let index = 0;
      let value = {};
      if (check) {
        if (val.pathname?.indexOf("geofencing") > -1) {
          value = orgMenusAccess.find(
            (item) =>
              `${`/organisation/org-structure/${item.km_master_forms.FORM_SLUG.toLocaleLowerCase()}`}` ===
              `${"/organisation/org-structure/location_master"}`
          );
        } else {
          value = orgMenusAccess.find(
            (item) =>
              `/organisation/org-structure/${item.km_master_forms.FORM_SLUG.toLocaleLowerCase()}` ===
              `${val?.pathname}`
          );
        }
        index = childrenTabsData.findIndex((item) => item?.ID === value?.ID);
      } else {
        value = childrenTabsData.find(
          (item) => `/${item?.url}` === `${val?.pathname}`
        );
        if (!isEmpty(value)) {
          index = childrenTabsData.findIndex((item) => item.id === value.id);
        }
      }
      setUpdateStateName(index === -1 ? childrenTabsData.length - 1 : index);
    }
  };

  useEffect(() => {
    if (!isEmpty(childrenTabsData)) {
      // const val = location;
      let index = null;
      let value = {};
      value = childrenTabsData.find(
        (item) => `${location?.pathname}`.includes(`/${item?.url}`)
      );
      if (!isEmpty(value)) {
        index = childrenTabsData.findIndex((item) => item?.id === value?.id);
      }
      setUpdateStateName(index === -1 ? childrenTabsData?.length - 1 : index);
    }
  }, [updateName, dispatch, childrenTabsData, menu_access, location]);

  const getItemName = (item) => {
    if (
      item?.menu_slug &&
      Object.keys(defaultConfigLabelMenuSlugs).includes(item?.menu_slug)
    ) {
      let {
        KRA_LABEL,
        KPI_LABEL,
        COMPETENCY,
        POTENTIALS,
        KEY_RESULT_LABEL
      } = getConfigLabels();

      const configLabelMenuSlugs = {
        ORG_KRAS: KRA_LABEL,
        ORG_KPIS: KPI_LABEL,
        ORG_COMPETENCIES: COMPETENCY,
        ORG_POTENTIAL: POTENTIALS,
        ORG_KEY_RESULT: KEY_RESULT_LABEL
      };

      return configLabelMenuSlugs?.[item?.menu_slug] || item?.name;
    }
    return intl.formatMessage({ id: `navbar.${item?.name}`, defaultMessage: item?.name });
  };

  const showSecondaryHeader = React.useMemo(
    () => !internalFixedAuthRoutesUrls.includes(location.pathname.trim()),
    [location]
  );

  return (
    <>
      {childrenTabsData?.length && showSecondaryHeader ? (
        <Box
          sx={{
            sm: 3,
            md: 5,
            lg: 6.4,
            width: "100%",
          }}
          className="flex-end-center header-box"
        >
          <Grid container className="wh-100 flex-around-center">
            <Grid item md={10} className="flex-end-center">
              <Tabs
                className={
                  "flex-center wh-100 header-tabs secondary-header-tabs"
                }
                centered
                value={updateName}
                indicator={"tranparent"}
                transition="none"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {childrenTabsData.map((item, index) => (
                  <>
                    <Tab
                      className="tab-header"
                      id={`${item?.name}`}
                      key={item?.id + index + Math.floor(Math.random() * 100)}
                      onClick={(e) => {
                        history(`/${item.url}`);
                        onTabChange();
                      }}
                      value={index === -1 ? false : index}
                      sx={{
                        opacity: "1 !important",
                        minWidth: "40px !important",
                        mx: { lg: 0.75, md: 0.5, sm: 0.3 },
                        p: 0,
                        minHeight: "45px !important",
                        height: "45px !important",
                      }}
                      label={
                        <Box
                          sx={{
                            color:
                              index === updateName
                                ? "primary.main"
                                : "rgba(35, 31, 32, 0.5)",
                            "&:hover": {
                              color: ptc,
                            },
                            fontWeight: 500,
                          }}
                          className="wh-100 flex-column"
                        >
                          <Box
                            sx={{ height: 45 }}
                            className="w-100 flex-center"
                          >
                            <Typography
                              sx={{
                                mr: 0.5,
                                ml: 0.5,
                              }}
                              color={"inherit"}
                              variant="h3"
                              className="header-name"
                            >
                              {getItemName(item)}
                            </Typography>
                            {/* <hr /> 
                        //not required . Creates dot.
                        */}
                          </Box>
                          <Box
                            className="box-radius"
                            style={
                              updateName === index
                                ? {
                                  backgroundColor: "none",
                                }
                                : { backgroundColor: "transparent" }
                            }
                          />
                        </Box>
                      }
                    />
                  </>
                ))}
              </Tabs>
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </>
  );
};
export default React.memo(Header);
